import React from 'react';
import './style.scss';
const Contact = () => {
    return (
        <div className="contact">
            <h1>CONTACT US :</h1>
            <h3>contact @shirogames.com</h3>
            <a className='contact-link' href='https://shirogames.com/more-about-us/'> need more info ?</a>
        </div>
    );
};
Contact.propTypes = {};
export default Contact;
