import React from 'react';

import { useSelector } from 'react-redux'
import './style.scss';

const Welcome = () => {
  const home = useSelector(state => state.home.accueil);
  return (
    <div className='welcome'>
      <h1 className='welcome-p'>Welcome !</h1>
      <h2 className='welcome-rotate'>Join us on our official discord servers ...</h2>
      <div className='welcome-servers'>
        <a className='welcome-servers-link' href={`https://discord.gg/${home.discord_evoland}`}>
          <img className='welcome-servers-img' src={home.icon_evoland} alt='' />
        </a>
        <a className='welcome-servers-link' href={`https://discord.gg/${home.discord_northgard}`}>
          <img className='welcome-servers-img' src={home.icon_northgard} alt='' id='northgard'/>
        </a>
        <a className='welcome-servers-link' href={`https://discord.gg/${home.discord_darksburg}`}>
          <img className='welcome-servers-img' src={home.icon_darksburg} alt='' />
        </a>
        <a className='welcome-servers-link' href={`https://discord.gg/${home.discord_wartales}`}>
          <img className='welcome-servers-img' src={home.icon_wartales} alt='' />
        </a>
        <a className='welcome-servers-link' href={`https://discord.gg/${home.discord_dune}`}>
          <img className='welcome-servers-img' src={home.icon_dune} alt='' />
        </a>
      </div>
      <h2 className='welcome-rotate'>And discover a wonderful community ! ♥</h2>
      
    </div>
  );
};

Welcome.propTypes = {
};

export default Welcome;
