import React from 'react';
import './style.scss';

const Login = () => {
    let gotit = false
    function handleClick() {
        if (gotit === false) {
            let igotitButtonParent = document.querySelector('.login-igotit');
            igotitButtonParent.innerHTML = '<a class="login-discord-link" href="https://discord.com/oauth2/authorize?client_id=994226552260808724&amp;redirect_uri=https://hub.shiro-community.com/dashboard/auth&amp;response_type=code&amp;scope=identify">LOGIN</a>';
            gotit = true;
        }
    };
    return (
        <div className="login">
            <h3 className="login-title">Note</h3>
            <p className="login-p">If a site/application offers you to authenticate with Discord, always check that the link of the login page begins with this :</p>
            <img className="login-img" src="https://www.aht.li/3712536/discorddomain.png" alt="discord official domain" />
            <p className="login-p">After Discord gives us it's and your approval, you will be redirected to the dashboard. Read our TOS.</p>
            <div className="login-igotit" onClick={handleClick}>
                <button className="login-igotit-button">
                    I got it
                </button>
            </div>
        </div>
    );
};

Login.propTypes = {};

export default Login;
