import React from 'react';
import './style.scss';
import Markdown from 'markdown-to-jsx';
const Guide = () => {
    return (
        <div className="guide">
            <h1>Be careful when navigating Discord</h1>
            <div>
                <a className='guide-imgsource' href='https://www.artstation.com/julien_mailly'>
                    <img className='guide-img' src="https://cdna.artstation.com/p/assets/images/images/003/227/746/large/jeremy-vitry-hd-panel01.jpg?1471351547" alt=''></img>
                </a>
            </div>

            <div className='guide-boxes'>
                <div className='guide-boxes-box'>
                    <h3>Be extremely vigilant</h3>
                    <Markdown>
                        {"It's most likely a **scam** if anyone (friends or strangers) contacts you for the following reason:\n\n\"I made a game, try it\"\n\n\"Get all my knifes i don't need them\"\n\n\"I accidentally reported you on steam\"\n\nFree nitro, free skins, fake discord system messages,\n\nlove scams, fake business school & courses,\n\nfake official websites, nft & coin-scams...\n\n[source](https://ripley.link/blog)\n"}
                    </Markdown>
                </div>
                <div className='guide-boxes-box'>
                    <h3>Five steps to a super safe account</h3>
                    <Markdown>{"-1. Secure your account.\n\n-2. Set your privacy & safety settings.\n\n-3. Follow safe account practices.\n\n-4. Block other users when needed.\n\n-5. Report to our moderators every suspicious dm.\n\n[source](https://discord.com/safety/360043857751-Four-steps-to-a-super-safe-account)"}</Markdown>
                </div>
                <div className='guide-boxes-box'>
                    <h3>Our recommendations</h3>
                    <Markdown>
                        {"Use __different passwords__ for discord and the email address you use for it, as well as __two-factor__ authentication. Really.\n\n__Don't share__ your login credentials. __Never__ !\n\nIf someone asks you for a __secret token__, dont give it.\n\n__Never paste code__ that someone told you to paste into your discord client or the discord website.\n\n__Don't use__ any alternative discord clients or __selfbots__. It's forbidden by discord tos, and your data may __leak__.\n\nShiro Games"}
                    </Markdown>
                </div>
            </div>
        </div>
    );
};
Guide.propTypes = {};
export default Guide;
