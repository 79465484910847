export const GET_INFO = 'GET_INFO';
export const getInfo = () => ({
  type: GET_INFO,
});
export const STORE_INFO = 'STORE_INFO';
export const storeInfo = (info) => ({
  type: STORE_INFO,
  home: info,
});
export const TOTO = 'TOTO';
export const toto = (token) => ({
  type: TOTO,
  toto: token
});
export const SECOND = 'SECOND';
export const second = (token, pass) => ({
  type: SECOND,
  toto: token,
  secret: pass
});
export const STORE_TOTO = 'STORE_TOTO'
export const storeToto = (user) => ({
  type: STORE_TOTO,
  user: user,
});  
export const GET_CONFIG = 'GET_CONFIG';
export const getConfig = () => ({
  type: GET_CONFIG,
});
export const STORE_CONFIG = 'STORE_GONFIG'
export const storeConfig = (config) => ({
  type: STORE_CONFIG,
  guilds: config
});
export const SELECT = 'SELECT';
export const select = (selected) => ({
  type: SELECT,
  selected
});
export const STORE_SELECTION = 'STORE_SELECTION';
export const storeSelection = (selected) => ({
  type: STORE_SELECTION,
  selected
});
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const updateConfig = () => ({
  type: UPDATE_CONFIG,
});
export const GET_LOGS = 'GET_LOGS';
export const getLogs = (token) => ({
  type: GET_LOGS,
  token: token
});
export const STORE_LOGS = 'STORE_LOGS';
export const storeLogs = (logs) => ({
  type: STORE_LOGS,
  logs: logs
});
export const SELECT_LOG = 'SELECT_LOG';
export const selectLog = (serv, sanction) => ({
  type: SELECT_LOG,
  serv: serv,
  sanction: sanction
});
export const UPDATE_VOCAL = 'UPDATE_VOCAL';
export const updateVocal = () => ({
  type: UPDATE_VOCAL,
});
export const UPDATE_GUILDS = 'UPDATE_GUILDS';
export const updateGuilds = (gid, vid) => ({
  type: UPDATE_GUILDS,
  gid: gid,
  vid: vid
});

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const getFeedback = (token, guild) => ({
  type: GET_FEEDBACK,
  token: token,
  guild: guild
});
export const STORE_FEEDBACK = 'STORE_FEEDBACK';
export const storeFeedback = (tags, feedback) => ({
  type: STORE_FEEDBACK,
  tags: tags,
  feedback: feedback
});

export const GET_TAGS = 'GET_TAGS';
export const getTags = (token, guild) => ({
  type: GET_TAGS,
  token: token,
  guild: guild
});
export const STORE_TAGS = 'STORE_TAGS';
export const storeTags = (tags) => ({
  type: STORE_TAGS,
  tags: tags
});
export const CLEAR_TAGS = 'CLEAR_TAGS';
export const clearTags = () => ({
  type: CLEAR_TAGS
});

export const HANDLE_TOGGLE = 'HANDLE_TOGGLE';
export const handleToggle = (tags, tag) => ({
  type: HANDLE_TOGGLE,
  tags: tags,
  tag: tag
});

export const SEARCH_BY_SIMILARITY = 'SEARCH_BY_SIMILARITY';
export const searchBySimilarity = (feedback, list, tags) => ({
  type: SEARCH_BY_SIMILARITY,
  feedback: feedback,
  list: list,
  tags: tags
});

export const STORE_SIMILARITY = 'STORE_SIMILARITY';
export const storeSimilarity = (feedback, feedbacklist) => ({
  type: STORE_SIMILARITY,
  feedback: feedback,
  feedbacklist: feedbacklist

});

export const CLEAR_SIMILARITY = 'CLEAR_SIMILARITY';
export const clearSimilarity = (feedback, feedbacklist) => ({
  type: CLEAR_SIMILARITY,
  feedback: feedback,
  feedbacklist: feedbacklist

});