import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux'
const Navbar = () => {

	const isLogged = useSelector(state => state.home.user.logged);
	const user = useSelector(state => state.home.user);
	return (
		<header className="header">
			<div className='header-left'>
				<a href='https://shirogames.com' className='header-left-link'>
					<img src='https://www.aht.li/3712507/sg.png' alt='logo' className='header-left-link-logo'></img>
					<p className='header-left-link-p'>Shiro Games</p>
				</a>
			</div>
			<div className='header-center'>
				<Link to="/" className="header-center-link">Dashboard</Link>
				{/* isLogged === 'yes' ? user.staff !== 'nope' &&  <Link to="/commands" className="header-center-link">Commands</Link> : "" */}
				{/* isLogged === 'yes' ? user.staff !== 'nope' &&  <Link to="/features" className="header-center-link">Features</Link> : "" */}
			</div>
			<div className='header-right'>
				{ isLogged === 'yes' && <img className='header-right-avatar' alt=' ' src={`https://cdn.discordapp.com/avatars/${user.id.split('-')[0]}/${user.id.split('-')[1]}.png`} /> }
				{ isLogged !== 'yes' ? <Link to="/login" className="header-right-link">Login</Link> : <a href="https://discord-bot.shirogames.com/" className="header-right-link">Logout</a> }
			</div>
		</header>
	);
};

Navbar.propTypes = {};
export default Navbar;
