import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import Welcome from './Welcome';
import Dashboard from './Dashboard';
const Home = ({
  getInfo,
}) => {
  useEffect(getInfo, [getInfo]);
  const isLogged = useSelector(state => state.home.user.logged);
    return (
      <div className="dashboard">
        {
          isLogged === 'yes' ? <Dashboard/> : <Welcome/>
        }
      </div>
    );
};

Home.propTypes = {};

export default Home;
