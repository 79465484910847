import { connect } from 'react-redux';
import Home from '../../components/App/Home';
import { getInfo, getConfig, select, updateConfig } from '../../actions';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => {
    dispatch(getInfo());
  },
  getConfig: () => {
    dispatch(getConfig());
  },
  select: () => {
    dispatch(select());
  },
  updateConfig: () => {
    dispatch(updateConfig())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
 