import React from 'react';
import './style.scss';
const Features = () => {
    return (
        <div className="features">
            <img src='https://www.aht.li/3712515/GIF_19-09-2020_09-07-00.gif' alt='automatic vocal channel'></img>
        </div>
    );
}; 
Features.propTypes = {};
export default Features;
