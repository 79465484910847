
import React from 'react';
import Similarity from './Similarity';
import Feedback from './Feedback';
import { useSelector } from 'react-redux';
import './style.scss';

const Dev = () => {
  const feedbackbysimilarity = useSelector(state => state.home.feedbackbysimilarity);
  return (
    feedbackbysimilarity ? <Similarity /> : <Feedback />
  )
};

Dev.propTypes = {};

export default Dev;
