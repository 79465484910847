import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../../src/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import homeMiddleware from '../middlewares/homeMiddleware';
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['user', 'guilds', 'selected'],
  whitelist: ['accueil'],
};

const pReducer = persistReducer(persistConfig, reducer);

const middlewares = applyMiddleware(
  //ajax,
  homeMiddleware,
);

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(middlewares);
// export default store;
export const store = createStore(pReducer, enhancers);
export const persistor = persistStore(store);