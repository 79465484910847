/* eslint-disable no-unused-vars */

import React from 'react'; // , {useEffect}
import { useSelector, useDispatch } from 'react-redux'; //
//import { Link } from 'react-router-dom';
//import User from '../User';
import Mod from '../../Mod';
import Feedbacklist from './Feedbacklist';
import './style.scss';

const Feedback = () => {
    const user = useSelector(state => state.home.user);
  const home = useSelector(state => state.home.accueil);
  const feedback = useSelector(state => state.home.feedback);
  const tags = useSelector(state => state.home.tags);
  const feedbackbysimilarity = useSelector(state => state.home.feedbackbysimilarity);
  const dispatch = useDispatch();
  !tags && dispatch({ type: "GET_TAGS", guild: "905073732920442890", token: user.toto }); // dune offi 905073732920442890 | dune copie 1014297371980603482
  if (!feedback) {
    dispatch({ type: "GET_FEEDBACK", guild: "905073732920442890", token: user.toto });
  }
  let z = 0;
  let servSelected = '975777419959107684';
  const selectServer = (event) => {
    switch (event.target.id) {
      case 'northgard':
        servSelected = '225335295128633345';
        dispatch({ type: "SELECT_FEEDBACK", serv: servSelected, sanction: feedback.sanction });
        dispatch({ type: "GET_TAGS", guild: servSelected, token: user.toto });
        break;
      case 'wartales':
        servSelected = '800668959736987669';
        dispatch({ type: "SELECT_FEEDBACK", serv: servSelected, sanction: feedback.sanction });
        dispatch({ type: "GET_TAGS", guild: servSelected, token: user.toto });
        break;
      case 'dune':
        servSelected = '905073732920442890';
        dispatch({ type: "SELECT_FEEDBACK", serv: servSelected, sanction: feedback.sanction });
        dispatch({ type: "GET_TAGS", guild: servSelected, token: user.toto });
        break;
      default:
        console.log('hi');
        break;
    }
  };
  const handleToggleChange = (event) => {
    console.log(event.target)
    dispatch({ type: "HANDLE_TOGGLE", tags: tags, tag: event.target.id });/*token: user.toto*/
  };
  return (
    <div>
      <div className='dev'>
        <div className='dev-feedback'>
          <div className='dev-feedback-menu'>
            <div className='dev-servers'>
              <img className='dev-servers-server' id='dune' src={home.icon_dune} alt='server' onClick={selectServer} />
              <img className='dev-servers-server' id='wartales' src={home.icon_wartales} alt='server' onClick={selectServer} />
              <img className='dev-servers-server' id='northgard' src={home.icon_northgard} alt='server' onClick={selectServer} />
            </div>
          </div>
          <div className='dev-feedback-tags'>
            {
              tags ? tags.map((t) => (
                <div key={z++} className='feedback-tags-box'>
                  <label className='dev-feedback-tags-t' value={t.id}>
                    <p style={{ color: t.color }}>{t.name}</p>
                    {t.toggle === true ? //<input id="1014297372001583225" class="dev-feedback-tags-t-input" type="checkbox" value="1014297372001583225" checked="">
                      <input defaultChecked={true} value={t.id} id={t.id} type="checkbox" className='dev-feedback-tags-t-input' onClick={handleToggleChange} /> :
                      <input defaultChecked={false} onClick={handleToggleChange} value={t.id} id={t.id} type="checkbox" className='dev-feedback-tags-t-input' />}
                  </label>
                </div>
              )) : <p>chargement</p>
            }
          </div>
          {feedback && <Feedbacklist />}          
        </div>
      </div>
      <Mod />
    </div>
  );
};

Feedback.propTypes = {};

export default Feedback;
