import React from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux'; //useSelector, 
import { useLocation, Navigate } from "react-router-dom";
const Auth = () => {
    const dispatch = useDispatch()
    const search = useLocation().search;
    const secret = new URLSearchParams(search).get('secret');
    const token = new URLSearchParams(search).get('code');
    const isLogged = useSelector(state => state.home.user.logged);
    if (token && !isLogged && !secret) {
        dispatch({ type: "TOTO", toto: token })
        return <Navigate to="/"/>
    } else if (token && !isLogged && secret) {
        dispatch({ type: "SECOND", toto: token, secret: secret })
        return <Navigate to="/"/>
    };
    if (isLogged) { return <Navigate to="/"/> }
    return (
        <div className="Auth">
        </div>
    );
};
Auth.propTypes = {};
export default Auth;