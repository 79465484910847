import React from 'react';
import './style.scss';
const Commands = () => {
    return (
        <div className="commands">
            <div className='commands-items'>
                <p className='commands-items-command'>!h</p>
                <p className='commands-items-info'>display commands and bot information</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!g</p>
                <p className='commands-items-info'>display the guild configuration</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!v</p>
                <p className='commands-items-info'>automate a vocal channel</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!k @member</p>
                <p className='commands-items-info'>kick a member</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!b @member</p>
                <p className='commands-items-info'>ban a member</p>
            </div>
            <div className='commands-items'>
                <p className='commands-items-command'>!m @member</p>
                <p className='commands-items-info'>mute a member for 28 days</p>
            </div>
            
        </div>
    );
};

Commands.propTypes = {};
export default Commands;
