
import React from 'react'; // , {useEffect}
import { useSelector, useDispatch } from 'react-redux'; //
//import { Link } from 'react-router-dom';
//import User from '../User';
import './style.scss';

const Similaritylist = () => {
    const feedbackbysimilarity = useSelector(state => state.home.feedbackbysimilarity);
    const list = useSelector(state => state.home.feedback);
    const tags = useSelector(state => state.home.tags);
    const filteredTagIds = tags.filter(tag => tag.toggle).map(tag => tag.id);
    let y = 0;
    const dispatch = useDispatch();
    const searchBySimilarity = (event) => {
        const feedbackIdToCompare = event.target.id;
        const feedbackToCompare = list.find(f => f.msg_id === feedbackIdToCompare)
        dispatch({ type: "SEARCH_BY_SIMILARITY", feedback: feedbackToCompare, list: list, tags: tags });
    };
    
    return (
        <div className='dev-sim-list'>

            {feedbackbysimilarity.map(f => {
                const isVisible = f.tags.filter(tag => filteredTagIds.includes(tag)).length > 0;
                if (!isVisible) return null;

                return (
                    <div key={y++} className='dev-sim-list-f' >
                        <div className='dev-sim-list-f-top'>
                            <div className='dev-sim-list-f-top-left'>
                                <img className='dev-sim-list-f-top-left-useravatar' src={f.user_avatar} width='36' height='36' alt="user_avatar"></img>
                                <p>{f.user_tag}</p>
                            </div>
                            <div className='dev-sim-list-f-top-percent'>Similarity: {f.similarity}%</div>
                            <div className='dev-sim-list-f-top-right'>
                                <p>Last edit : <a href={f.msg_url} className='dev-sim-list-f-top-right-date' target="_blank" rel="noreferrer noopener">{new Date(parseInt(f.last_update)).toDateString()} </a></p>
                            </div>
                        </div>
                        <div className='dev-sim-list-f-mid'>
                            <p> {f.msg_content}</p>
                        </div>
                        <div className='dev-sim-list-f-bot'>
                            <div className='dev-sim-list-f-bot-left'>
                                <p className='dev-sim-list-f-bot-left-yes'>✅ {f.vote_yes}</p>
                                <p className='dev-sim-list-f-bot-left-no'>❌ {f.vote_no}</p>
                            </div>
                            <p onClick={searchBySimilarity} className='dev-sim-list-f-bot-right' id={f.msg_id}>🔎</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

Similaritylist.propTypes = {};

export default Similaritylist;
