
import React from 'react'; // , {useEffect}
//import Mod from '../Mod';
import Dev from '../Dev';

import './style.scss';

const Admin = () => {
  return ( 
    <div>
      <Dev />
    </div>
  );
};

Admin.propTypes = {};

export default Admin;
