import React from 'react';
import './style.scss';
const Terms = () => {
    return (
        <div className="terms">
            <h1>About this project :</h1>
            <h4>At Shiro Games, transparency is important to us.</h4>
            <p>This is a translation. In case of dispute, the French version will prevail: In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of June 21, 2004 on Confidence in the digital economy, known as LCEN, Users of the site discord-bot.shirogames.com these legal notices: The connection and navigation on the site discord-bot.shirogames.com by the User implies full and unreserved acceptance of these legal notices and our conditions of use. These are accessible on the site under the heading “Terms”.</p>

            <h2>ARTICLE 1: The publisher</h2>
            <p>The edition and the direction of the publication of the site https://discord-bot.shirogames.com are ensured by Earlam (Moderator) and Dynamo (CM) at Shiro Games.</p>

            <h2>ARTICLE 2: The host</h2>
            <p>The host of the site https://discord-bot.shirogames.com are ensured by Azrou (Admin) at Shiro Games.</p>

            <h2>ARTICLE 3: Access to the site</h2>
            <p>The site is accessible from any location, 7 days a week, 24 hours a day, except in cases of "force majeure", scheduled or unscheduled interruption and possibly resulting from a need for maintenance. In the event of modification, interruption or suspension of the services, the site https://discord-bot.shirogames.com cannot be held responsible.</p>

            <h2>ARTICLE 4: Data collection</h2>
            <p>The site https://discord-bot.shirogames.com is not exempt from declaration to the Commission Nationale Informatique et Libertés (CNIL) insofar as it collects data concerning users. The data we collect on the site https://dicord.shiro.fr are: the public discord identifier and the IP address of the User at the time of login. The data we collect on the bot are: the user's public discord identifier, information relating to the user's behavior (note, warn, mute, kick, ban), the status relating to the activity of the user shared by himself with his rich presence, the games's feedbacks that the user shares with us in order to improve our games. We don't keep data that is no longer useful to us. We do not sell or share your data.</p>
            <h2>SECTION 5: Cookies</h2>
            <p>We don't use cookies on this site :)</p>
            <h2>ARTICLE 6: Intellectual property</h2>
            <p>Any use, reproduction, distribution, marketing, modification of all or part of the site https://discord-bot.shirogames.com, without the authorization of the Publisher (Shiro Games) is prohibited and may result in legal action and prosecution as provided for in particular by the Code. intellectual property and the Civil Code.</p>
            <h2>ARTICLE 7: Finally</h2>
            <p>This contract is valid for an indefinite period. The beginning of the use of the services of the site and/or the bot mark the application of the contract with regard to the User. This contract is subject to French law. The absence of amicable resolution of cases of dispute between the parties implies recourse to the competent French courts to settle the dispute. </p>
        </div>
    );
};
Terms.propTypes = {};
export default Terms;
