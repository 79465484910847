import React from 'react';
import { useSelector } from 'react-redux'
import User from './User';
import Mod from './Mod';
import Dev from './Dev';
import Admin from './Admin';

import './style.scss';

const Dashboard = () => {
  const user = useSelector(state => state.home.user);
  switch(user.staff){
    default : return <User/>;
    case 'mod': return <Mod/>;
    case 'dev': return <Dev/>;
    case 'admin': return <Admin/>;
  }
};

Dashboard.propTypes = {

};

export default Dashboard;
