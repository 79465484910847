import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Home from '../../containers/Home';
import Navbar from './Navbar';
import Footer from './Footer';
import Login from './Login';
import Logout from './Logout';
import Features from './Features';
import Contact from './Contact';
import Terms from './Terms';
import Guide from './Guide';
import Commands from './Commands';
import Auth from './Auth';

import './style.scss';
const App = ({
  getInfo,
}) => {
  useEffect(getInfo, [getInfo]);
  const handleClick = () => {};
  return (
    <div className="app" onClick={handleClick}>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/commands" element={<Commands />}/>
        <Route exact path="/terms" element={<Terms />}/>
        <Route exact path="/features" element={<Features />}/>
        <Route exact path="/contact" element={<Contact />}/>
        <Route exact path="/guide" element={<Guide />}/>
        <Route exact path="/login" element={<Login />}/>
        <Route exact path="/dashboard/auth" element={<Auth />}/>
        <Route exact path="/logout" element={<Logout />}/>
        {/*<Route exact path="/auth" element={<Navigate to="/"/>}/>*/}
        <Route path="/*" element={<Navigate to="/"/>}/>
        <Route element={<Home />}/>
      </Routes> 
      <Footer />
    </div>
  );
};

App.propTypes = {};

export default App;
