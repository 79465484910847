/* eslint-disable max-len */
import React from 'react'; // , {useEffect}

import { useSelector, useDispatch } from 'react-redux'
//import { select } from '../../../../actions';
//import { getConfig } from '../../../../actions';


//import { useLocation } from "react-router-dom";
import User from '../User';
//import Config from './Config';

import './style.scss';

const Mod = () => {//, getConfig
  //useEffect(getConfig, [getConfig]);
  //useEffect(toto, [toto]);
  const user = useSelector(state => state.home.user);
  const home = useSelector(state => state.home.accueil);
  const logs = useSelector(state => state.home.logs);
  const log = useSelector(state => state.home.log);
  //console.log(user)
  //const guilds = useSelector(state => state.home.guilds);
  //const selected = useSelector(state => state.home.selected);
  //const guilds = useSelector(state => state.home.guilds);
  //const guild = guilds.find((g) => g.guild_id === id);
  //console.log(guild) 
  const dispatch = useDispatch()
  !logs && dispatch({ type: "GET_LOGS", token: user.toto });
  let x = 0;
  let servSelected = '975777419959107684';
  let sanctionSelected = 'all';
  const selectServer = (event) => {
    //console.log(event.target.id)
    switch (event.target.id) {
      case 'evoland':
        servSelected = '975777419959107684';
        dispatch({ type: "SELECT_LOG", serv: servSelected, sanction: log.sanction });
        break;
      case 'northgard':
        servSelected = '225335295128633345';
        dispatch({ type: "SELECT_LOG", serv: servSelected, sanction: log.sanction });
        break;
      case 'darksburg':
        servSelected = '615813015576772609';
        dispatch({ type: "SELECT_LOG", serv: servSelected, sanction: log.sanction });
        break;
      case 'wartales':
        servSelected = '800668959736987669';
        dispatch({ type: "SELECT_LOG", serv: servSelected, sanction: log.sanction });
        break;
      case 'dune':
        servSelected = '905073732920442890';
        dispatch({ type: "SELECT_LOG", serv: servSelected, sanction: log.sanction });
        break;
      default:
        console.log('hi');
        break;
    }
  };
  const selectSanction = (event) => {
    console.log(event.target.id)
    switch (event.target.id) {
      case 'all':
        sanctionSelected = 'all';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      case 'note':
        sanctionSelected = 'note';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      case 'warn':
        sanctionSelected = 'warn';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      case 'timeout':
        sanctionSelected = 'timeout';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      case 'kick':
        sanctionSelected = 'kick';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      case 'ban':
        sanctionSelected = 'ban';
        dispatch({ type: "SELECT_LOG", serv: log.serv, sanction: sanctionSelected });
        break;
      default:
        console.log('hi');
        break;
    }
  };
  return (
    <div>
      <div className='mod'>
        <div className='mod-servers'>
          <img className='mod-servers-server' id='evoland' src={home.icon_evoland} alt='server' onClick={selectServer} />
          <img className='mod-servers-server' id='northgard' src={home.icon_northgard} alt='server' onClick={selectServer} />
          <img className='mod-servers-server' id='darksburg' src={home.icon_darksburg} alt='server' onClick={selectServer} />
          <img className='mod-servers-server' id='wartales' src={home.icon_wartales} alt='server' onClick={selectServer} />
          <img className='mod-servers-server' id='dune' src={home.icon_dune} alt='server' onClick={selectServer} />
        </div>
        {/*<h1>{servDisplayed}</h1>*/}
        <div className='mod-buttons'>
          <p className='mod-buttons-button' id='all' onClick={selectSanction}> all</p>

          <p className='mod-buttons-button' id='note' onClick={selectSanction} >note</p>
          <p className='mod-buttons-button' id='warn' onClick={selectSanction} >warn</p>
          <p className='mod-buttons-button' id='timeout' onClick={selectSanction} >mute</p>
          <p className='mod-buttons-button' id='kick' onClick={selectSanction} >kick</p>
          <p className='mod-buttons-button' id='ban' onClick={selectSanction} >ban</p>
        </div>
        <div className='mod-logs'>
          {
            logs ? logs.map((l) => (

              l.serv_id === log.serv && ((log.sanction === l.action || log.sanction === 'all') && <p key={x++}>{l.author_tag} {l.action} {l.target_tag} {l.date.split(" GMT")[0]} {l.reason}</p>)


            )) : <p>chargement...</p>
          }
        </div>
      </div>
      <User />
    </div>
  );
};

Mod.propTypes = {};

export default Mod;