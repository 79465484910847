import React from 'react';
import './style.scss';

const Logout = () => {
    
    return (
        <div className="logout">
            <img className="logout-img" src="https://www.aht.li/3693839/aincrad.gif" alt="Aincrad" />
            <h3 className='logout-h1'>You can't, you are now stuck !</h3>
            <p className="logout-p">It's a joke, refresh the page to logout... UwU</p>
        </div>
    );
};

Logout.propTypes = {};

export default Logout;
