import {
  STORE_INFO,
  STORE_TOTO,
  STORE_SELECTION,
  STORE_LOGS,
  SELECT_LOG,
  STORE_FEEDBACK,
  STORE_TAGS,
  CLEAR_TAGS,
  HANDLE_TOGGLE,
  STORE_SIMILARITY,
  CLEAR_SIMILARITY,
} from '../actions';
const initialState = {
  accueil: {},
  user: {},
  guilds: [],
  selected: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_INFO: {
      return {
        ...state,
        accueil: action.home,
      };
    }
    case STORE_TOTO: {
      return {
        ...state,
        user: {
          logged: 'yes',
          id: action.user.user,
          toto: action.user.toto,
          staff: action.user.staff
        },
        guilds: action.user.guilds,
      };
    }

    case STORE_SELECTION: {

      return {
        ...state,
        selected: action.selected,
      };
    }
    case STORE_LOGS: {
      return {
        ...state,
        logs: action.logs,
        log: { serv: '975777419959107684', sanction: 'all' }
      };
    }
    case SELECT_LOG: {
      return { 
        ...state,
        log: { serv: action.serv, sanction: action.sanction }
      };
    }
    case STORE_FEEDBACK: {
      const taglist = action.tags;
      const feedback = action.feedback;
      const newFeedback = [];
      let feedbackTags = [];
      feedback.forEach(f =>{
        //console.log(f)
        let regexList = [];
        taglist.forEach(t => {let reg = new RegExp(`<@&${t.tag_id}>`);regexList.push({ regex: reg, name: `${t.tag_name}`, id: `${t.tag_id}`, color: `${t.tag_color}`})})
        let pushTagId = function(i) { feedbackTags.push(regexList[i].id) }
        regexList.some(({ regex }, index) => (regex.test(f.msg_content) && pushTagId(index)))
        newFeedback.push({ msg_content: f.msg_content, msg_id: f.msg_id, msg_url: f.msg_url, user_tag: f.user_tag, user_avatar:f.user_avatar, guild_id: f.guild_id, last_update: f.last_update, vote_yes: f.vote_yes, vote_no: f.vote_no, tags: feedbackTags })
        feedbackTags = [];
      });
      return {
        ...state,
        feedback: newFeedback
      };
    }
    case STORE_TAGS: {
      const tags = action.tags;
      const newTags = [];
      tags.forEach(tag => {
        newTags.push({ id: tag.tag_id, name: tag.tag_name, color: tag.tag_color, toggle: true })
      });
      return {
        ...state,
        tags: newTags
      };
    }
    case HANDLE_TOGGLE: {
      const tagList = action.tags;
      const tagToUpdate = action.tag;
      const newArray = [];
      tagList.forEach(t => {
        if(t.toggle === true){
          t.id !== tagToUpdate ? newArray.push(t) : newArray.push({id:t.id, name:t.name, color:t.color, toggle: false})
        } else {
          t.id !== tagToUpdate ? newArray.push(t) : newArray.push({id:t.id, name:t.name, color:t.color, toggle: true})
        }
      });
      return {
        ...state,
        tags: newArray
      };
    }
    case CLEAR_TAGS: {
      return {
        ...state,
        tags: ""
      };
    }
    case STORE_SIMILARITY: {
      const feedback = action.feedback;
      const feedbacklist = action.feedbacklist
      console.log(feedback)
      console.log(feedbacklist)
      return {
        ...state,
        feedbacktocompare: feedback,
        feedbackbysimilarity: feedbacklist
      };
    }
    case CLEAR_SIMILARITY: {
      return {
        ...state,
        feedbacktocompare: "",
        feedbackbysimilarity: ""
      };
    }
    default:
      return state;
  }
};

export default reducer;
