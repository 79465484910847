import React from 'react';
//import { useLocation } from "react-router-dom";
//import { useSelector } from 'react-redux'

import './style.scss';

const User = () => {
  //const user = useSelector(state => state.home.user);
  return (
    <div className='dashboard'>
      
      <img alt='' src='https://www.aht.li/3646005/Untitled_2_16.png'/>
      <h3>There is nothing here yet...</h3>
      
      {/*
      {
        user.staff ==='nope' && <img alt='' src='https://www.aht.li/3646005/Untitled_2_16.png'/>
      }
      {
        user.staff === 'nope' && <h3>There is nothing here yet...</h3>
      }
    */}
    </div>
  );
};

User.propTypes = {

};

export default User;
