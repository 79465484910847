/* eslint-disable no-unused-vars */

import React from 'react'; // , {useEffect}
import { useSelector, useDispatch } from 'react-redux'; //
//import { Link } from 'react-router-dom';
import Similaritylist from './Similaritylist';
import './style.scss';

const Similarity = () => {
    const feedbackCompared = useSelector(state => state.home.feedbacktocompare);
    const dispatch = useDispatch();
    const callClearSimilarity = () => {
        dispatch({ type: "CLEAR_SIMILARITY" });
    };
    return (
        <div className='dev-sim'>
            <div className='dev-sim-compared'>
                <div className='dev-sim-compared-top'>
                    <div className='dev-sim-compared-top-left'>
                        <img className='dev-sim-compared-top-left' src={feedbackCompared.user_avatar} width='36' height='36' alt="user_avatar"></img>
                        <p>{feedbackCompared.user_tag}</p>
                    </div>
                    <p className='dev-sim-compared-top-percent'>Feedback compared </p>
                    <div className='dev-sim-compared-top-right'>
                        <p>Last edit : <a href={feedbackCompared.msg_url} className='dev-feedback-list-f-top-right-date' target="_blank" rel="noreferrer noopener">{new Date(parseInt(feedbackCompared.last_update)).toDateString()} </a></p>
                    </div>
                </div>
                <div className='dev-sim-compared-mid'>
                    <p className='dev-sim-compared-mid-content'>{feedbackCompared.msg_content}</p>
                </div>
                <div className='dev-sim-compared-bot'>
                    <div className='dev-sim-compared-bot-left'>
                        <p className='dev-sim-compared-bot-left-yes'>✅ {feedbackCompared.vote_yes}</p>
                        <p className='dev-sim-compared-bot-left-no'>❌ {feedbackCompared.vote_no}</p>
                    </div>
                    <p onClick={callClearSimilarity} className='dev-sim-compared-bot-right' id={feedbackCompared.msg_id}>BACK</p>
                </div>
            </div>
            <Similaritylist />
        </div>
    );
};

Similarity.propTypes = {};

export default Similarity;
